import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReviewsUiExtensionModule } from './extensions/f7107194fef6b7b834a5c08088286128e762a919679edb5b6820582646438314/reviews-ui-extension.module';
import { InvoicesNavModule } from './extensions/072bbec4d85e5143d80673e651ef78f70d4a9fe854e67c12a1759d10d39b8821/invoices-nav.module';


@NgModule({
    imports: [CommonModule, ReviewsUiExtensionModule, InvoicesNavModule],
})
export class SharedExtensionsModule {}
